import { canViewFeedbacks } from '@calories/admin-backend/src/utils/can'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getFeedbackRoute, getUserRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const FeedbackPage = withPageWrapper({
  useQuery: () => {
    const { feedbackSerialNumber } = getFeedbackRoute.useParams()
    return trpc.getFeedback.useQuery({
      serialNumber: +feedbackSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    feedback: queryResult.data.feedback,
  }),
  checkAccess: ({ ctx }) => canViewFeedbacks(ctx.me),
  showLoaderOnFetching: false,
  title: ({ feedback }) => `Feedback: ${feedback.serialNumber}`,
})(({ feedback }) => {
  return (
    <Segment title={`Feedback: ${feedback.serialNumber}`} helpId="feedback-view">
      <div className={css.general}>
        <p>Date: {format(new Date(feedback.createdAt), 'yyyy-MM-dd HH:mm')}</p>
        <p>Category: {feedback.category}</p>
        <p>Rate: {feedback.rate || '—'}</p>
        <p>Message: {feedback.message || '—'}</p>
        <p>
          <Link to={getUserRoute({ userSerialNumber: feedback.user.serialNumber.toString() })}>
            User: {feedback.user.serialNumber}
          </Link>
        </p>
      </div>
    </Segment>
  )
})
