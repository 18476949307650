import { zCommentTicketTrpcInput } from '@calories/admin-backend/src/router/tickets/commentTicket/input'
import { canManageTickets, canViewTickets } from '@calories/admin-backend/src/utils/can'
import { format } from 'date-fns'
import capitalize from 'lodash/capitalize'
import { Link } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { getS3UploadName, UploadsToS3 } from '../../../components/UploadsToS3'
import { useMe } from '../../../lib/ctx'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getTicketRoute, getUserRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const TicketPage = withPageWrapper({
  useQuery: () => {
    const { ticketSerialNumber } = getTicketRoute.useParams()
    return trpc.getTicket.useQuery({
      serialNumber: +ticketSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    ticket: queryResult.data.ticket,
  }),
  checkAccess: ({ ctx }) => canViewTickets(ctx.me),
  showLoaderOnFetching: false,
  title: ({ ticket }) => `Ticket: ${ticket.serialNumber}`,
})(({ ticket }) => {
  const me = useMe()
  const commentTicket = trpc.commentTicket.useMutation()
  const trpcUtls = trpc.useUtils()
  const formComment = useForm({
    initialValues: {
      text: '',
      uploads: [],
    },
    validationSchema: zCommentTicketTrpcInput.omit({
      ticketId: true,
    }),
    onSubmit: async (values) => {
      const result = await commentTicket.mutateAsync({ ...values, ticketId: ticket.id })
      trpcUtls.getTicket.setData({ serialNumber: ticket.serialNumber }, result)
    },
    resetOnSuccess: true,
    successMessage: 'Comment created successfully',
  })

  const closeTicket = trpc.closeTicket.useMutation()
  const formClose = useForm({
    onSubmit: async () => {
      const result = await closeTicket.mutateAsync({ ticketId: ticket.id })
      trpcUtls.getTicket.setData({ serialNumber: ticket.serialNumber }, result)
    },
    resetOnSuccess: true,
    successMessage: 'Ticket closed successfully',
  })
  return (
    <Segment title={`Ticket: ${ticket.serialNumber}`} helpId="ticket-view">
      <Segment size={2} title={ticket.title} helpId={null}>
        <div className={css.general}>
          <p>Text: {ticket.text}</p>
          {!!ticket.uploads.length && (
            <div className={css.uploads}>
              {ticket.uploads.map((value) => (
                <div className={css.upload}>
                  File:{' '}
                  <a className={css.uploadLink} target="_blank" href={value} rel="noreferrer">
                    {getS3UploadName(value)}
                  </a>
                </div>
              ))}
            </div>
          )}
          <p className={css.secondary}>
            {!!ticket.hiddenUploads.length && (
              <>
                <p>Hidden uploads:</p>
                <div className={css.uploads}>
                  {ticket.hiddenUploads.map((value) => (
                    <div className={css.upload}>
                      File:{' '}
                      <a className={css.uploadLink} target="_blank" href={value} rel="noreferrer">
                        {getS3UploadName(value)}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}
            Date: {format(new Date(ticket.createdAt), 'yyyy-MM-dd HH:mm')}
            <br />
            Name: {ticket.name || '—'}
            <br />
            Email: {ticket.email || '—'}
            <br />
            Status: {ticket.closedAt ? 'Closed' : 'Active'}
            <br />
            Build: {ticket.buildVersion || '—'}
            <br />
            {ticket.user ? (
              <Link to={getUserRoute({ userSerialNumber: ticket.user.serialNumber.toString() })}>
                User: {ticket.user.serialNumber}
              </Link>
            ) : (
              'User: Anonymous'
            )}
          </p>
        </div>
      </Segment>
      {!!ticket.comments.length && (
        <Segment size={2} title="Comments" helpId={null}>
          {ticket.comments.map((comment) => (
            <div key={comment.id} className={css.comment}>
              <p className={css.secondary}>{capitalize(comment.authorRole)}</p>
              {comment.authorRole === 'user' && <p className={css.secondary}>Build: {comment.buildVersion}</p>}
              <p className={css.secondary}>Date: {format(new Date(comment.createdAt), 'yyyy-MM-dd HH:mm')}</p>
              <p>Text: {comment.text}</p>
              {!!comment.uploads.length && (
                <div className={css.uploads}>
                  {comment.uploads.map((value) => (
                    <div className={css.upload}>
                      File:{' '}
                      <a className={css.uploadLink} target="_blank" href={value} rel="noreferrer">
                        {getS3UploadName(value)}
                      </a>
                    </div>
                  ))}
                </div>
              )}
              {!!comment.hiddenUploads.length && (
                <>
                  <p>Hidden uploads:</p>
                  <div className={css.uploads}>
                    {comment.hiddenUploads.map((value) => (
                      <div className={css.upload}>
                        File:{' '}
                        <a className={css.uploadLink} target="_blank" href={value} rel="noreferrer">
                          {getS3UploadName(value)}
                        </a>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </Segment>
      )}

      {canManageTickets(me) && (
        <Segment size={2} title="New Comment" helpId={null}>
          <form onSubmit={formComment.formik.handleSubmit}>
            <FormItems>
              <Textarea label="Text" name="text" formik={formComment.formik} />
              <UploadsToS3 label="Uploads" name="uploads" formik={formComment.formik} />
              <Alert {...formComment.alertProps} />
              <Button {...formComment.buttonProps}>Create Comment</Button>
            </FormItems>
          </form>
        </Segment>
      )}

      {!ticket.closedAt && canManageTickets(me) && (
        <Segment size={2} title="Close Ticket" helpId={null}>
          <form onSubmit={formClose.formik.handleSubmit}>
            <FormItems>
              <Alert {...formClose.alertProps} />
              <Button {...formClose.buttonProps}>Close Ticket</Button>
            </FormItems>
          </form>
        </Segment>
      )}
    </Segment>
  )
})
