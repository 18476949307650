import { zStringRequired } from '@calories/shared/src/zod'
import { z } from 'zod'

export const zUpdateUpgradePostTrpcInput = z.object({
  id: z.string(),
  title: zStringRequired,
  body: zStringRequired,
  imageUrl: z.string().optional().nullable(),
  releaseVersion: z.string().regex(/^\d+\.\d+\.\d+$/, {
    message: 'Release version must be in the format of x.x.x',
  }),
})
