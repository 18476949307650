import { zGetFeedbacksTrpcInput } from '@calories/admin-backend/src/router/feedbacks/getFeedbacks/input'
import { canViewFeedbacks } from '@calories/admin-backend/src/utils/can'
import { format } from 'date-fns'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getFeedbackRoute, getUserRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetFeedbacksTrpcInput.pick({
  category: true,
})
const initialValues = {
  category: '',
}

export const FeedbacksPage = withPageWrapper({
  title: 'Feedbacks',
  checkAccess: ({ ctx }) => canViewFeedbacks(ctx.me),
})(() => {
  const searchForm = useForm({
    initialValues,
    validationSchema,
  })
  const filter = (() => {
    const maybeFilter = {
      category: searchForm.formik.values.category,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return (parseResult.success ? maybeFilter : initialValues) as typeof initialValues
  })()
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getFeedbacks.useInfiniteQuery(
      {
        ...filter,
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title="Feedbacks" helpId="feedbacks">
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Category" name="category" formik={searchForm.formik} />
        </FormItems>
      </div>
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].feedbacks.length ? (
        <Alert color="brown">Nothing found by search</Alert>
      ) : (
        <div className={css.feedbacks}>
          <table className={css.table}>
            <thead>
              <tr>
                <th>Created At</th>
                <th>Feedback SN</th>
                <th>User SN</th>
                <th>Category</th>
                <th>Rate</th>
                <th>Message</th>
              </tr>
            </thead>
            <InfiniteScroll
              threshold={250}
              loadMore={() => {
                if (!isFetchingNextPage && hasNextPage) {
                  void fetchNextPage()
                }
              }}
              className={css.tbody}
              hasMore={hasNextPage}
              loader={
                <tr>
                  <td className={css.more} key="loader">
                    <Loader type="section" />
                  </td>
                </tr>
              }
              getScrollParent={() => layoutContentElRef.current}
              useWindow={
                (layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'
              }
            >
              {data.pages
                .flatMap((page) => page.feedbacks)
                .map((feedback) => (
                  <tr className={css.feedback} key={feedback.serialNumber}>
                    <td>{format(new Date(feedback.createdAt), 'yyyy-MM-dd HH:mm')}</td>
                    <td>
                      <Link
                        className={css.feedbackLink}
                        to={getFeedbackRoute({
                          feedbackSerialNumber: feedback.serialNumber.toString(),
                        })}
                      >
                        {feedback.serialNumber}
                      </Link>
                    </td>
                    <td>
                      <Link
                        className={css.feedbackLink}
                        to={getUserRoute({
                          userSerialNumber: feedback.user.serialNumber.toString(),
                        })}
                      >
                        {feedback.user.serialNumber}
                      </Link>
                    </td>
                    <td>{feedback.category}</td>
                    <td>{feedback.rate || '—'}</td>
                    <td>
                      {!feedback.message
                        ? '—'
                        : feedback.message.length > 50
                        ? feedback.message.slice(0, 50) + '...'
                        : feedback.message}
                    </td>
                  </tr>
                ))}
            </InfiniteScroll>
          </table>
        </div>
      )}
    </Segment>
  )
})
